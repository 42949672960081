import { request } from '@/utils/request';

export function requestList(page: number) {
  const offset = (page - 1) * 20;
  return request({
    url: `https://api.nicebook.win/douyu/api/v1/live?limit=20&offset=${offset}`,
    method: 'GET',
  });
}

export function parseDouyu(id: string) {
  const token = localStorage.getItem('live-token');
  if (!token) return { success: false };
  return request({
    url: `https://api.nicebook.win/liveparse/douyu?token=${token}&id=${id}`,
    method: 'GET',
  });
}
