<template>
  <div class="player-page">
    <a-page-header :title="plat" :sub-title="roomName" @back="$router.back" />

    <div v-if="vUrl">
      <VideoFlv v-if="plat === 'douyu'" :url="vUrl"></VideoFlv>
      <VideoNormal v-if="plat === 'huya'" :url="vUrl" :config="playerConfig"></VideoNormal>

      <div>
        <a-textarea :defaultValue="vUrl" :auto-size="{ minRows: 2, maxRows: 6 }" />
        <a-button @click="copy(vUrl)">复制</a-button>
      </div>
      <div>
        <a :href="orgUrl" noreferrer>直播地址</a>
      </div>
    </div>
    <div class="loader" v-else-if="loading">
      <a-spin />
    </div>
    <div class="empty" v-else>
      暂无内容
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import XEClipboard from 'xe-clipboard';
import VideoNormal from '@/components/VideoNormal.vue';
import VideoFlv from '@/components/VideoFlv.vue';
import { parseDouyu } from '@/api/douyu';
import { parseHuya } from '@/api/huya';

export default defineComponent({
  name: 'Live',
  components: {
    VideoNormal,
    VideoFlv,
  },
  setup() {
    const route = useRoute();
    const plat = route.params.plat;
    const roomId = route.params.roomId;
    const roomName = route.query.title || '';

    const vUrl = ref('');
    const loading = ref(false);
    const orgUrl = ref('');
    const playerConfig = ref({});

    const getVideoSrc = async (plat: string, id: string) => {
      if (plat === 'douyu') {
        loading.value = true;
        const data = await parseDouyu(id);
        loading.value = false;
        if (data.success) {
          vUrl.value = data.data;
        } else {
          alert(data.data);
        }

        orgUrl.value = `https://www.douyu.com/${roomId}`;
      }

      if (plat === 'huya') {
        loading.value = true;
        const data = await parseHuya(id);
        loading.value = false;
        if (data.success) {
          vUrl.value = data.data['2000p'] || data.data.bd || data.data.hd;
        } else {
          alert(data.data);
        }

        const playerWidth = (document.querySelector('.player-page') as any).offsetWidth;
        playerConfig.value = {
          width: playerWidth,
        };
        console.log(playerWidth);
        orgUrl.value = `https://www.huya.com/${roomId}`;
      }
    };

    onMounted(() => {
      getVideoSrc(plat as string, roomId as string);
    });

    return {
      vUrl,
      orgUrl,
      plat,
      playerConfig,
      roomName,
      loading,
    };
  },

  methods: {
    onPlay() {
      console.log(2);
    },
    copy(str: string) {
      if (XEClipboard.copy(str)) {
        (this as any).$message.success('Copy success.');
      } else {
        (this as any).$message.warn('The browser is not supported.');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.player-page {
  max-width: 1200px;
  margin: 0 auto;
}
.loader {
  text-align: center;
  padding: 20px 0 10px;
}
.empty {
  text-align: center;
  padding: 20px 0 10px;
}
</style>
