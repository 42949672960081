<template>
  <div class="huya-page">
    <div class="list-wrap">
      <a-card class="card" v-for="item in list" :key="item.profileRoom" @click="goPlay(item)">
        <div><img :alt="item.introduction" :src="item.screenshot" /></div>
        <div>{{ item.introduction }}</div>
      </a-card>
    </div>
    <div class="loader" v-if="loading">
      <a-spin />
    </div>
    <div class="more" v-else-if="!over" @click="loadNextPage">
      更多
    </div>
    <a-back-top />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { requestList } from '@/api/huya';

export default defineComponent({
  name: 'HuyaLive',
  setup() {
    const activePlat = ref('1');
    const page = ref(1);
    const list = ref([]);
    const loading = ref(false);
    const over = ref(false);

    const getList = async () => {
      loading.value = true;
      const data = await requestList(page.value);
      loading.value = false;
      if (data.data.datas?.length) {
        list.value = list.value.concat(data.data.datas);
      } else {
        over.value = true;
      }
    };

    const setNextPage = () => {
      page.value += 1;
    };

    onMounted(getList);

    return {
      activePlat,
      page,
      setNextPage,
      list,
      getList,
      loading,
      over,
    };
  },

  methods: {
    onChangePlat(v: string) {
      console.log(v);
    },
    loadNextPage() {
      this.setNextPage();
      this.getList();
    },
    goPlay(item: any) {
      this.$router.push(`/live/play/huya/${item.profileRoom}?title=${item.introduction}`);
    },
  },
});
</script>

<style lang="scss" scoped>
.list-wrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card {
  width: 48%;

  img {
    width: 100%;
  }
}
.loader {
  text-align: center;
  padding: 20px 0 10px;
}
.more {
  text-align: center;
  color: blue;
  padding: 20px 0 10px;
}
</style>
