<template>
  <div class="live-page">
    <a-row>
      <a-col :span="18">
        <a-input v-model:value="token" placeholder="token"></a-input>
      </a-col>
      <a-col>
        <a-button @click="saveToken">保存</a-button>
      </a-col>
    </a-row>

    <a-tabs type="card" :defaultActiveKey="plat" @change="onChangePlat">
      <a-tab-pane key="douyu" tab="斗鱼直播">
        <Douyu></Douyu>
      </a-tab-pane>
      <a-tab-pane key="huya" tab="虎牙">
        <Huya></Huya>
      </a-tab-pane>
      <a-tab-pane key="bilibili" tab="Bilibili">
        Content of Tab Pane 3
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Douyu from './Douyu.vue';
import Huya from './Huya.vue';

export default defineComponent({
  name: 'Live',
  components: {
    Douyu,
    Huya,
  },
  setup() {
    const route = useRoute();
    const plat = route.params.plat;
    const activePlat = ref('1');
    const token = ref('');

    onMounted(() => {
      const savedToken = localStorage.getItem('live-token') || '';
      token.value = savedToken;
    });

    return {
      activePlat,
      plat,
      token,
    };
  },

  methods: {
    onChangePlat(plat: string) {
      this.$router.replace(`/live/${plat}`);
    },
    saveToken() {
      localStorage.setItem('live-token', this.token);
      (this as any).$message.success('已保存');
    },
  },
});
</script>

<style lang="scss" scoped>
.live-page {
  max-width: 800px;
  margin: 0 auto;
}
</style>
