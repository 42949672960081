<template>
  <div class="video">
    <video id="videoElement" controls autoplay></video>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import flvjs from 'flv.js';

export default defineComponent({
  name: 'Video',
  props: {
    url: String,
    config: Object,
  },
  setup(props: any) {
    let flvPlayer: any = null;

    onMounted(() => {
      if (flvjs.isSupported()) {
        const videoElement = document.getElementById('videoElement');
        flvPlayer = flvjs.createPlayer({
          type: 'flv',
          isLive: true,
          hasAudio: true,
          ...(props.config || {}),
          url: props.url,
        });

        console.log(flvPlayer, 'flv对象');
        flvPlayer.attachMediaElement(videoElement as any);
        flvPlayer.volume = 0.5;
        flvPlayer.load();
        flvPlayer.play();
      }
    });

    onBeforeUnmount(() => {
      if (flvPlayer) flvPlayer.destroy();
    });
  },
});
</script>

<style lang="scss" scoped>
.video {
  video {
    width: 100%;
  }
}
</style>
