<template>
  <div class="video">
    <video id="video" class="video-js" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-flash';

export default defineComponent({
  name: 'Video',
  props: {
    url: String,
    config: Object,
  },
  setup(props) {
    let player: any = null;

    onMounted(() => {
      const element = document.getElementById('video');
      player = videojs(
        element,
        {
          controls: true,
          autoplay: 'play',
          width: 600,
          ...(props.config || {}),
          sources: [
            {
              src: props.url,
              type: 'application/x-mpegURL',
            },
          ],
        },
        function() {
          player.volume(0.5);
        }
      );

      return {
        player,
      };
    });

    onBeforeUnmount(() => {
      if (player) player.dispose();
    });

    watch(
      () => props.config,
      (val, prev) => {
        console.log(val, prev);
      }
    );
  },
});
</script>

<style lang="scss" scoped></style>
