import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { message } from 'ant-design-vue';
import { LOCALSTORAGENAME_TOKEN } from '@/constants/storage';

export const request = (options: AxiosRequestConfig) => {
  const token = localStorage.getItem(LOCALSTORAGENAME_TOKEN);

  const defaultOptions = {
    // timeout: 20 * 1000,
  };

  if (token) {
    // (defaultOptions as any).headers = {
    //   'Authorization': 'Basic ' + token,
    // };
  }

  return axios.create(defaultOptions).request(options).then(res => {
    if (res.status === 200) {
      return res.data
    } else {
      return new Error(res.status.toString());
    }
  }).catch(err => {
    // 错误统一处理
    console.error(err);
    message.error(err.message);
    return new Error(err.message);
  });
}
