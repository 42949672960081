
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import XEClipboard from 'xe-clipboard';
import VideoNormal from '@/components/VideoNormal.vue';
import VideoFlv from '@/components/VideoFlv.vue';
import { parseDouyu } from '@/api/douyu';
import { parseHuya } from '@/api/huya';

export default defineComponent({
  name: 'Live',
  components: {
    VideoNormal,
    VideoFlv,
  },
  setup() {
    const route = useRoute();
    const plat = route.params.plat;
    const roomId = route.params.roomId;
    const roomName = route.query.title || '';

    const vUrl = ref('');
    const loading = ref(false);
    const orgUrl = ref('');
    const playerConfig = ref({});

    const getVideoSrc = async (plat: string, id: string) => {
      if (plat === 'douyu') {
        loading.value = true;
        const data = await parseDouyu(id);
        loading.value = false;
        if (data.success) {
          vUrl.value = data.data;
        } else {
          alert(data.data);
        }

        orgUrl.value = `https://www.douyu.com/${roomId}`;
      }

      if (plat === 'huya') {
        loading.value = true;
        const data = await parseHuya(id);
        loading.value = false;
        if (data.success) {
          vUrl.value = data.data['2000p'] || data.data.bd || data.data.hd;
        } else {
          alert(data.data);
        }

        const playerWidth = (document.querySelector('.player-page') as any).offsetWidth;
        playerConfig.value = {
          width: playerWidth,
        };
        console.log(playerWidth);
        orgUrl.value = `https://www.huya.com/${roomId}`;
      }
    };

    onMounted(() => {
      getVideoSrc(plat as string, roomId as string);
    });

    return {
      vUrl,
      orgUrl,
      plat,
      playerConfig,
      roomName,
      loading,
    };
  },

  methods: {
    onPlay() {
      console.log(2);
    },
    copy(str: string) {
      if (XEClipboard.copy(str)) {
        (this as any).$message.success('Copy success.');
      } else {
        (this as any).$message.warn('The browser is not supported.');
      }
    },
  },
});
