<template>
  <div class="mobile home">
    <h1 class="title">S8 Posts</h1>
    <a-row>
      <a-col :span="8">
        <a-input v-model:value="token" placeholder="token"></a-input>
      </a-col>
      <a-col :span="8">
        <a-input v-model:value="customHost" placeholder="Host示例: https://www.aaa.cc"></a-input>
      </a-col>
      <a-col>
        <a-button @click="saveToken">保存</a-button>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="18">
        <a-input
          v-model:value="searchCnt"
          placeholder="搜索标题"
          v-on:keyup.enter="search"
        ></a-input>
      </a-col>
      <a-col>
        <a-button @click="search">搜索</a-button>
      </a-col>
    </a-row>

    <div class="info">
      <span class="ordering-control">
        <span>排序：</span>
        <a-radio-group v-model:value="ordering" button-style="solid">
          <a-radio value="-post_date">
            发布时间
          </a-radio>
          <a-radio value="-crawl_date">
            爬取时间
          </a-radio>
        </a-radio-group>
      </span>

      <span>共 {{ totalCount }} 条</span>

      <a-button class="refresh-btn" @click="refresh">刷新</a-button>
    </div>

    <a-pagination
      v-if="totalCount"
      class="pager"
      v-model:current="currentPage"
      :show-total="total => `共 ${total} 条`"
      :pageSize="pageSize"
      :total="totalCount"
      show-less-items
    />

    <a-list item-layout="horizontal" :data-source="listData" :loading="listLoading">
      <template #renderItem="{ item, index }">
        <a-list-item :key="index">
          <a-list-item-meta>
            <template #title>
              <a :href="getLinkById(item.tid)" class="list-item" target="_blank" rel="nofollow">
                <span class="title">{{ item.title }}</span>
                <div>
                  <small>发布: {{ doFormatDate(item.postAt) }}</small
                  ><small style="margin-left:10px">抓取: {{ doFormatDate(item.createdAt) }}</small>
                </div>
                <ul v-if="item.pictures">
                  <li
                    class="img-list-item"
                    v-for="imgsrc in getImgsByMlink(item.pictures)"
                    :key="imgsrc"
                  >
                    <img v-lazy="imgsrc" />
                  </li>
                </ul>
              </a>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

    <a-pagination
      v-if="totalCount"
      class="pager"
      v-model:current="currentPage"
      :show-total="total => `共 ${total} 条`"
      :pageSize="pageSize"
      :total="totalCount"
      show-less-items
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import * as s8API from '@/api/s8';
import { LOCALSTORAGENAME_TOKEN, LOCALSTORAGENAME_HOST } from '@/constants/storage';
import { useRouter, useRoute } from 'vue-router';
import { formatDate } from '@/utils/date';

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const token = ref('');
    const customHost = ref(localStorage.getItem(LOCALSTORAGENAME_HOST) || '');
    const searchCnt = ref('');
    const listData = ref([]);
    const listLoading = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(20);
    const totalCount = ref(0);
    const ordering = ref('-post_date');
    const tempsrc = ref('http://fill-image.jobs160.com/200');

    const getList = async (page = 1) => {
      if (
        localStorage.getItem('casetoken') !== 'aHZhbmtlOjI0NDk3N2hnbA==' &&
        localStorage.getItem('casetoken') !== '123321'
      ) {
        return;
      }

      listLoading.value = true;
      const limit = pageSize.value;
      const offset = limit * (page - 1);
      const data = await s8API.requestList({
        page: page || 1,
        search: searchCnt.value || undefined,
        limit,
        offset,
        ordering: ordering.value,
      });
      listLoading.value = false;
      if (data?.data) {
        totalCount.value = data.data.total || 9999;
        listData.value = data.data.articles || [];
      } else {
        totalCount.value = 0;
        listData.value = [];
      }
    };

    const resetPage = () => {
      currentPage.value = 1;
    };

    onMounted(() => {
      currentPage.value = Number(route.query.page) || 1;

      if (!token.value) {
        const val = localStorage.getItem(LOCALSTORAGENAME_TOKEN);
        if (val) {
          token.value = val;
          getList(currentPage.value);
        }
      } else {
        getList(currentPage.value);
      }

      // hack
      (window as any).ttt = 'aHZhbmtlOjI0NDk3N2hnbA==';
    });

    watch(ordering, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(currentPage, val => {
      listData.value = [];
      getList(val);

      router.replace({
        query: {
          page: val,
        } as any,
      });
    });

    return {
      tempsrc,
      token,
      customHost,
      searchCnt,
      ordering,
      currentPage,
      pageSize,
      totalCount,
      listData,
      listLoading,
      resetPage,
      getList,
    };
  },
  methods: {
    saveToken() {
      if (this.token) {
        localStorage.setItem(LOCALSTORAGENAME_TOKEN, this.token);
        localStorage.setItem(LOCALSTORAGENAME_HOST, this.customHost);
        (this as any).$message.success('已保存');
      }
    },
    search() {
      this.resetPage();
      this.getList();
    },
    getLinkById(id: string) {
      const host = this.customHost || 'https://www.sex8.cc';
      return `${host}/thread-${id}-1-1.html`;
    },
    refresh() {
      this.getList();
    },
    getImgsByMlink(mlink: string) {
      if (!mlink) return [];
      return mlink.split('$$$');
    },
    doFormatDate(str: string) {
      return formatDate(str, 'YYYY-MM-DD HH:mm');
    },
  },
});
</script>

<style lang="scss" scoped>
.home {
  padding: 20px 20px 10px;
}
.title {
  font-size: 12px;
  text-align: center;
}
ul {
  list-style: none;
  display: flex;
}
.img-list-item {
  width: 32%;
  margin-right: 10px;
  img {
    width: 100%;
  }
}
.ordering-control {
  margin-right: 50px;
}
.info {
  padding: 20px 0;
  text-align: right;
}
.refresh-btn {
  margin-left: 20px;
}
.pager {
  text-align: right;
}
</style>
