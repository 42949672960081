import { request } from '@/utils/request';
import { RequestWeiboListParams } from './models/weibo';

export function requestWeiboList(params: RequestWeiboListParams) {
  return request({
    url: 'https://api.nicebook.win/api/weibo/?format=json',
    method: 'GET',
    params,
  });
}
