import { request } from '@/utils/request';
import { RequestListParams } from './models/s8';

export function requestList(params: RequestListParams) {
  return request({
    url: 'http://qhkproxy.199100.xyz:5000/seight',
    method: 'GET',
    params,
  });
}
