import { request } from '@/utils/request';

export function requestList(page: number) {
  return request({
    url: 'https://api.nicebook.win/huya/cache.php',
    method: 'GET',
    params: {
      m: 'LiveList',
      do: 'getLiveListByPage',
      page: page,
      pageSize: 120,
    },
  });
}

export function parseHuya(id: string) {
  const token = localStorage.getItem('live-token');
  if (!token) return { success: false };

  return request({
    url: `https://api.nicebook.win/liveparse/huya?token=${token}&id=${id}`,
    method: 'GET',
  });
}
