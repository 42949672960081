
import { defineComponent, onMounted, ref, watch } from 'vue';
import { requestList } from '@/api/douyu';

export default defineComponent({
  name: 'DouyuLive',
  setup() {
    const activePlat = ref('1');
    const page = ref(1);
    const list = ref([]);
    const loading = ref(false);
    const over = ref(false);

    const getList = async () => {
      loading.value = true;
      const data = await requestList(page.value);
      loading.value = false;
      if (data.data?.length) {
        list.value = list.value.concat(data.data);
      } else {
        over.value = true;
      }
    };

    const setNextPage = () => {
      page.value += 1;
    };

    onMounted(getList);

    return {
      activePlat,
      page,
      setNextPage,
      list,
      getList,
      loading,
      over,
    };
  },

  methods: {
    onChangePlat(v: string) {
      console.log(v);
    },
    loadNextPage() {
      this.setNextPage();
      this.getList();
    },
    goPlay(item: any) {
      this.$router.push(`/live/play/douyu/${item.room_id}?title=${item.room_name}`);
    },
  },
});
