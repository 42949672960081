<template>
  <div class="mobile home">
    <h1 class="title">Trending</h1>
    <div class="widget">
      <span>日期: </span>
      <a-date-picker
        @change="onChangeDate"
        :default-value="defaultDate"
        value-format="YYYY-MM-DD"
      />
    </div>

    <div class="info">
      <span class="ordering-control">
        <span>排序：</span>
        <a-radio-group v-model:value="ordering" button-style="solid">
          <a-radio value="-date"> 时间 </a-radio>
          <a-radio value="-hot"> 热度 </a-radio>
        </a-radio-group>
      </span>

      <span>共 {{ totalCount }} 条</span>

      <a-button class="refresh-btn" @click="refresh">刷新</a-button>
    </div>

    <a-pagination
      v-if="totalCount"
      class="pager"
      v-model:current="currentPage"
      :pageSize="pageSize"
      :total="totalCount"
      show-less-items
    />

    <div class="list-wrapper">
      <a-list item-layout="horizontal" :data-source="listData" :loading="listLoading">
        <template #renderItem="{ item, index }">
          <a-list-item :key="index">
            <a-list-item-meta>
              <template #title>
                <a :href="getWeiboLinkByTopic(item.title)" class="list-item" target="_blank">
                  <div>
                    <span class="list-item-title">{{ item.title }}</span>
                    <span class="hot"><FireOutlined /> {{ item.hot }}</span>
                  </div>
                  <div>
                    <!-- <span class="date">{{ $filters.dateFormat(item.date) }}</span> -->
                    <span class="date">{{ item.date }}</span>
                  </div>
                </a>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <a-pagination
      v-if="totalCount"
      class="pager"
      v-model:current="currentPage"
      :pageSize="pageSize"
      :total="totalCount"
      show-less-items
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { FireOutlined } from '@ant-design/icons-vue';
import { requestWeiboList } from '@/api/weibo';

export default defineComponent({
  name: 'Home',
  components: {
    FireOutlined,
  },
  setup() {
    const listData = ref([]);
    const listLoading = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalCount = ref(0);
    const ordering = ref('-hot');

    const now = new Date();
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    const defaultDate = `${now.getFullYear()}-${month}-${day}`;
    const selectedDate = ref(defaultDate);

    const getList = async (page = 1) => {
      listLoading.value = true;
      const limit = pageSize.value;
      const offset = limit * (page - 1);
      const data = await requestWeiboList({
        search: selectedDate.value,
        ordering: ordering.value as any,
        limit,
        offset,
      });
      listLoading.value = false;
      if (data?.results) {
        totalCount.value = data.count || 0;
        listData.value = data.results || [];
      } else {
        totalCount.value = 0;
        listData.value = [];
      }
    };

    onMounted(getList);
    watch(selectedDate, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(ordering, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(currentPage, val => {
      listData.value = [];
      getList(val);
    });

    return {
      ordering,
      currentPage,
      pageSize,
      totalCount,
      listData,
      listLoading,
      defaultDate,
      selectedDate,
      getList,
    };
  },
  methods: {
    getWeiboLinkByTopic(topic: string) {
      return `https://s.weibo.com/weibo?q=%23${topic}%23`;
    },
    onChangeDate(val: string) {
      this.selectedDate = val;
    },
    refresh() {
      this.getList();
    },
  },
});
</script>

<style lang="scss" scoped>
.home {
  padding: 0 20px 10px;
}
.title {
  font-size: 14px;
  text-align: center;
}
.widget {
  text-align: center;
}
.list-wrapper {
  min-height: 30px;
  padding-top: 15px;
}
.list-item-title {
  font-size: 16px;
  margin-right: 10px;
}
.list-item .hot,
.list-item .date {
  font-size: 12px;
  color: #999;
}
.ordering-control {
  margin-right: 30px;
}
.info {
  padding: 20px 0;
  text-align: right;
}
.refresh-btn {
  margin-left: 20px;
}
.pager {
  text-align: right;
}
</style>
