
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Douyu from './Douyu.vue';
import Huya from './Huya.vue';

export default defineComponent({
  name: 'Live',
  components: {
    Douyu,
    Huya,
  },
  setup() {
    const route = useRoute();
    const plat = route.params.plat;
    const activePlat = ref('1');
    const token = ref('');

    onMounted(() => {
      const savedToken = localStorage.getItem('live-token') || '';
      token.value = savedToken;
    });

    return {
      activePlat,
      plat,
      token,
    };
  },

  methods: {
    onChangePlat(plat: string) {
      this.$router.replace(`/live/${plat}`);
    },
    saveToken() {
      localStorage.setItem('live-token', this.token);
      (this as any).$message.success('已保存');
    },
  },
});
