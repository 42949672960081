
import { defineComponent, onMounted, ref, watch } from 'vue';
import { FireOutlined } from '@ant-design/icons-vue';
import { requestWeiboList } from '@/api/weibo';

export default defineComponent({
  name: 'Home',
  components: {
    FireOutlined,
  },
  setup() {
    const listData = ref([]);
    const listLoading = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalCount = ref(0);
    const ordering = ref('-hot');

    const now = new Date();
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    const defaultDate = `${now.getFullYear()}-${month}-${day}`;
    const selectedDate = ref(defaultDate);

    const getList = async (page = 1) => {
      listLoading.value = true;
      const limit = pageSize.value;
      const offset = limit * (page - 1);
      const data = await requestWeiboList({
        search: selectedDate.value,
        ordering: ordering.value as any,
        limit,
        offset,
      });
      listLoading.value = false;
      if (data?.results) {
        totalCount.value = data.count || 0;
        listData.value = data.results || [];
      } else {
        totalCount.value = 0;
        listData.value = [];
      }
    };

    onMounted(getList);
    watch(selectedDate, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(ordering, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(currentPage, val => {
      listData.value = [];
      getList(val);
    });

    return {
      ordering,
      currentPage,
      pageSize,
      totalCount,
      listData,
      listLoading,
      defaultDate,
      selectedDate,
      getList,
    };
  },
  methods: {
    getWeiboLinkByTopic(topic: string) {
      return `https://s.weibo.com/weibo?q=%23${topic}%23`;
    },
    onChangeDate(val: string) {
      this.selectedDate = val;
    },
    refresh() {
      this.getList();
    },
  },
});
