
import { defineComponent, onMounted, ref, watch } from 'vue';
import * as s8API from '@/api/s8';
import { LOCALSTORAGENAME_TOKEN, LOCALSTORAGENAME_HOST } from '@/constants/storage';
import { useRouter, useRoute } from 'vue-router';
import { formatDate } from '@/utils/date';

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const token = ref('');
    const customHost = ref(localStorage.getItem(LOCALSTORAGENAME_HOST) || '');
    const searchCnt = ref('');
    const listData = ref([]);
    const listLoading = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(20);
    const totalCount = ref(0);
    const ordering = ref('-post_date');
    const tempsrc = ref('http://fill-image.jobs160.com/200');

    const getList = async (page = 1) => {
      if (
        localStorage.getItem('casetoken') !== 'aHZhbmtlOjI0NDk3N2hnbA==' &&
        localStorage.getItem('casetoken') !== '123321'
      ) {
        return;
      }

      listLoading.value = true;
      const limit = pageSize.value;
      const offset = limit * (page - 1);
      const data = await s8API.requestList({
        page: page || 1,
        search: searchCnt.value || undefined,
        limit,
        offset,
        ordering: ordering.value,
      });
      listLoading.value = false;
      if (data?.data) {
        totalCount.value = data.data.total || 9999;
        listData.value = data.data.articles || [];
      } else {
        totalCount.value = 0;
        listData.value = [];
      }
    };

    const resetPage = () => {
      currentPage.value = 1;
    };

    onMounted(() => {
      currentPage.value = Number(route.query.page) || 1;

      if (!token.value) {
        const val = localStorage.getItem(LOCALSTORAGENAME_TOKEN);
        if (val) {
          token.value = val;
          getList(currentPage.value);
        }
      } else {
        getList(currentPage.value);
      }

      // hack
      (window as any).ttt = 'aHZhbmtlOjI0NDk3N2hnbA==';
    });

    watch(ordering, () => {
      currentPage.value = 1;
      totalCount.value = 0;
      getList(1);
    });
    watch(currentPage, val => {
      listData.value = [];
      getList(val);

      router.replace({
        query: {
          page: val,
        } as any,
      });
    });

    return {
      tempsrc,
      token,
      customHost,
      searchCnt,
      ordering,
      currentPage,
      pageSize,
      totalCount,
      listData,
      listLoading,
      resetPage,
      getList,
    };
  },
  methods: {
    saveToken() {
      if (this.token) {
        localStorage.setItem(LOCALSTORAGENAME_TOKEN, this.token);
        localStorage.setItem(LOCALSTORAGENAME_HOST, this.customHost);
        (this as any).$message.success('已保存');
      }
    },
    search() {
      this.resetPage();
      this.getList();
    },
    getLinkById(id: string) {
      const host = this.customHost || 'https://www.sex8.cc';
      return `${host}/thread-${id}-1-1.html`;
    },
    refresh() {
      this.getList();
    },
    getImgsByMlink(mlink: string) {
      if (!mlink) return [];
      return mlink.split('$$$');
    },
    doFormatDate(str: string) {
      return formatDate(str, 'YYYY-MM-DD HH:mm');
    },
  },
});
